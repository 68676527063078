import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

type Track = {
  id: string;
  audioFile: string;
  waveColor: string;
  progressColor: string;
  cursorColor: string;
};

function initAudioWaveVisualizer(container: HTMLElement) {
  const wave: HTMLDivElement = container.querySelector('.js-wave');

  const wavesurfer = WaveSurfer.create({
    container: wave,
    responsive: true,
    hideScrollbar: true,
    waveColor: 'white',
    progressColor: 'hsl(18deg, 98%, 50%)',
    cursorColor: 'hsl(18deg, 98%, 50%)',
    barWidth: 8,
    barRadius: 4,
    barGap: 8,
    cursorWidth: 2,
    height: 126,
    plugins: [
      CursorPlugin.create({
        showTime: false,
        opacity: 1,
        width: '2px',
        color: '#2B343D',
      })
    ]
  });

  wavesurfer.on('ready', () => {
    const waveform: HTMLElement = wave.querySelector('wave');
    waveform.style.cursor = 'ew-resize';
  });

  const toggleContainer = container.querySelector('.js-audio-wave-toggles');
  const playPauseToggle: HTMLButtonElement = container.querySelector('.js-play-pause');
  const muteToggle: HTMLButtonElement = container.querySelector('.js-mute');
  const trackToggles: Array<HTMLButtonElement> = Array.from(container.querySelectorAll('.js-audio-wave-toggle'));
  const tracks: Array<Track> = [];
  let toggleState = toggleContainer.getAttribute('data-checked') === 'true';

  const updateTrackById = (id: string) => {
    const track: Track = tracks.find((t) => t.id === id);

    wavesurfer.on('ready', () => {
      wavesurfer.setProgressColor(track.progressColor);
      wavesurfer.setWaveColor(track.waveColor);
      wavesurfer.setCursorColor(track.progressColor);
      wave.classList.remove('is-loading');
    });

    wavesurfer.on('loading', (number) => {
      wave.classList.add('is-loading');
    });

    wavesurfer.load(track.audioFile);
  };

  const setBackgroundImageById = (id: string) => {
    const images: Array<HTMLElement> = Array.from(container.querySelectorAll('.js-audio-wave-background-image'));
    const targetImage: HTMLElement | undefined = images.find((img) => img.getAttribute('data-id') === id);
    images.forEach((img) => img.classList.remove('is-active'));
    if (targetImage === undefined) return;
    targetImage.classList.add('is-active');
  };

  const setToggleById = (id: string) => {
    trackToggles.forEach((t) => t.classList.remove('is-active'));
    const toggle: HTMLButtonElement = trackToggles.find((t) => t.getAttribute('data-id') === id);
    toggle.classList.add('is-active');
    toggleState = !toggleState;
    toggleContainer.setAttribute('data-checked', toggleState.toString());
  };

  const setAudioFileById = (id: string) => {
    updateTrackById(id);
    setToggleById(id);
    setBackgroundImageById(id);
    playPauseToggle.setAttribute('data-paused', 'true');
  };

  trackToggles.forEach((track) => {
    const id = track.getAttribute('data-id');
    const audioFile = track.getAttribute('data-audio-file');
    const waveColor = track.getAttribute('data-wave-color');
    const progressColor = track.getAttribute('data-progress-color');
    const cursorColor = track.getAttribute('data-cursor-color');

    tracks.push({
      id,
      audioFile,
      waveColor,
      progressColor,
      cursorColor,
    });

    track.addEventListener('click', (e) => {
      const target = e.target as HTMLButtonElement;
      const targetId = target.getAttribute('data-id');
      setAudioFileById(targetId);
    });
  });

  wavesurfer.once('ready', () => {
    playPauseToggle.addEventListener('click', (e) => {
      const target = e.target as HTMLButtonElement;
      let paused = target.getAttribute('data-paused') === 'true';
      wavesurfer.playPause();
      paused = !paused;
      target.setAttribute('data-paused', paused.toString());
    });

    muteToggle.addEventListener('click', (e) => {
      const target = e.target as HTMLButtonElement;
      let muted = target.getAttribute('data-muted') === 'true';
      wavesurfer.toggleMute();
      muted = !muted;
      target.setAttribute('data-muted', muted.toString());
    });
  });

  // load the first audio file
  setAudioFileById(tracks[0].id);
}

const audioWaveVisualizers: Array<HTMLElement> = Array.from(
  document.querySelectorAll('.js-audio-wave-visualizer'),
);
audioWaveVisualizers.forEach(initAudioWaveVisualizer);
